<template>
    <v-app>
        <v-dialog v-model="showDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span>Cargar manifiesto. Courier: {{courier}}</span>
                </v-card-title>
                <v-card-text>
                    <v-simple-table fixed-header height="200px" >
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center"> URL Manifiesto </th>
                                    <th class="text-center"> Subir Archivo </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td><v-text-field label="url archivo" v-model="url"></v-text-field></td>
                                    <td>
                                        <v-file-input
                                            v-model="file" color="deep-purple accent-4" label="Subir manifiesto" placeholder="Sube una archivo formato pdf,jpeg o png."
                                            prepend-icon="mdi-paperclip" outlined :show-size="1000" @change="uploadFile()" :disabled="loading || file_uploaded"
                                        >
                                        <template v-slot:selection="{ text }"><v-chip color="deep-purple accent-4" dark label small > {{ text }} </v-chip></template>
                                        </v-file-input>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <br>
                    <v-btn @click="sendForm" :disabled="loading || orders.some(e => !url)"><v-icon left>mdi-content-save</v-icon> Guardar</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
export default {
    data()
    {
        return{
            orders: [],
            couriersList: [],
            loading: false,
            showDialog: false,
            courier: false,
            file_uploaded: undefined,
            url: undefined,
            file: undefined
        }
    },
    created()
    {
        this.$parent.$on('openModalCargarManifiestos', (orders, courier) => {
            this.orders = [];
            this.courier = courier;
            this.orders = orders.map(order => ({
                ...order,
                file_uploaded: false,
                url: undefined,
                courier: order.courier,
                file: undefined
            }));
            this.showDialog = true;
        }); 
    },
    //mounted() { },
    methods: {
        uploadFile() {
            if(this.file instanceof File && !this.file_uploaded && !this.loading){
                console.log('se tiene que subir el archivo');
                this.loading = true;
                this.$nextTick(() => {
                    let formData = new FormData();
                    formData.append("file", this.file, this.file.name);
                    formData.append("order_reference", "");
                    formData.append("source", "");
                    var vm = this;
                    this.axios({
                        url: 'orders/upload_shipping_label',
                        data: formData,
                        method: 'POST'
                    }).then( response => {
                        vm.url = response.data;
                        vm.file_uploaded = true;
                        vm.loading = false;
                        
                        vm.$bvToast.toast(
                            'Archivo subido con exito', { title: `Notificación`, variant: "success", solid: true, toaster: "b-toaster-bottom-center" }
                        );
                    }).catch( error => {
                        vm.loading = false;
                    });
                })
            }
        },
        sendForm(){
            var vm = this;
            this.axios({
                url: 'shippings/manifests_bulk_load',
                method: 'POST',
                data:   {   
                    shipments: this.orders.map(e => ({ id: e.id })),
                    url: vm.url,
                    courier: vm.courier
                }
            }).then( response => {
                vm.$bvToast.toast(
                    'Manifiestos cargados con exito', { title: `Notificación`, variant: "success", solid: true, toaster: "b-toaster-bottom-center" }
                );
                vm.$emit('success');
                vm.showDialog = false;
                vm.file_uploaded = false;
                vm.url = undefined;
                vm.file = undefined;
            }).catch( error => {

            });
        }
    }
}
</script>