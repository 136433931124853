<template>
    <div>
    <v-app>
        <div class="row">
            <div class="col">
                <v-card>
                    <v-card-title>
                        Cargar Manifiestos(s)
                        <v-spacer></v-spacer>
                        <v-btn class="" @click="fetchDataPaginated"  text icon color="blue">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-card-title>
            <v-card-text>
              <v-card outlined tile elevation="1">
                <v-card-text style="padding-bottom: 0px;">
                  <div class="d-flex justify-space-between mb-4">
                    <v-text-field
                      v-model="search"
                      clearable
                      label="Buscar"
                      hide-details
                      dense
                      solo
                      @change="fetchDataPaginated"
                      class="mr-2"
                    ></v-text-field>
                    <v-btn class="btn-starkoms-primary" dark style="min-width: 138px;" @click="fetchDataPaginated" >Buscar</v-btn>
                  </div>
                  <div class="d-flex justify-space-between">
                    <div class="d-flex flex-column">
                      <span class="text-h6 font-weight-regular black--text"><i class="icon-filter_calendar"></i> Filtro por fecha</span>
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        style="border-bottom: 1px solid #000;"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            @click:clear="limpiarRango"
                            v-model="dateRangeText"
                            placeholder="Todos"
                            solo
                            readonly
                          ></v-text-field>
                        </template>
                        <v-date-picker id="datepicker-starkoms" @input="formatearRango" range v-model="dates"></v-date-picker>
                      </v-menu>
                    </div>
                    <div class="d-flex flex-column">
                      <span class="text-h6 font-weight-regular black--text"><i class="icon-shipments"></i> Filtro por courier</span>
                      <v-select
                        @change="verRefSelect"
                        class="ml-2 mr-2"
                        label="Filtro por courier"
                        ref="select_carrier"
                        :items="carrierList"
                        v-model="selectCarrier"
                        item-text="text"
                        @input="fetchDataPaginated"
                        solo
                      ></v-select>
                    </div>
                    <div class="d-flex">
                      <v-btn
                        v-show="!!search || !!dateRangeText || selectCarrier != 'Todos' "
                        @click="limpiarFiltros"
                        class="align-self-center" dark color="grey"
                      >Limpiar Filtros</v-btn>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
                    <v-card-text>
                        <h3 class="title">Seleccione el(los) pedido(s) que desea cargar manifiesto: {{selected.length ? (selected.length > 1 ? '('+selected.length+') Seleccionados' : '(1) Seleccionado'):''}}</h3>
                        <v-data-table
                            :headers="headers"
                            :items="tableData"
                            show-select
                            v-model="selected"
                            no-results-text="No hay resultados disponibles"
                            no-data-text="No hay datos disponibles"
                            loading-text="Cargando datos..."
                            :sort-by="'id'"
                            :sort-desc="true"
                            class="elevation-1 datatable"
                            :loading="dataTableLoading"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :items-per-page="selectItemsPerPage"
                        >
                            <template v-slot:item.order_id="{ item }">
                                <td v-if="item.order_id" class="text-center"><a @click="$router.push({name: 'orden por ref', params: {ref: item.order_reference}})">{{item.order_id}}</a></td>
                                <td v-if="!item.order_id" class="text-center">{{item.order_id}}</td>
                            </template>
                            <template v-slot:item.shipping_number="{ item }">
                                <td v-if="item.tracking_carrier" class="text-center"><a :href="item.tracking_carrier" target="_blank" >{{item.shipping_number}}</a></td>
                                <td v-if="!item.tracking_carrier" class="text-center">{{item.shipping_number}}</td>
                            </template>
                            <template v-slot:item.updated_at="{ item }">
                                <td class="text-center">{{$moment(item.updated_at).format('lll')}}</td>
                            </template>
                            <template v-slot:item.estado="{ item }">
                            <td class="text-center"> </td>
                            </template>
                            <template v-slot:item.acciones="{ item }">
                            <td class="d-flex">
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                    default
                                    color="#8C8C8C"
                                    class="mr-2"
                                    v-on="on"
                                    @click="$router.push({name: 'envio', params: {envio: item.id}})"
                                    >mdi-arrow-right-box</v-icon>
                                </template>
                                <span>Ver detalles</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                    default
                                    color="#8C8C8C"
                                    class="mr-2"
                                    v-on="on"
                                    @click="$router.push({name: 'orden por ref', params: {ref: item.order_reference}})"
                                    >mdi-cart-arrow-right</v-icon>
                                </template>
                                <span>Ver orden</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                    default
                                    color="#8C8C8C"
                                    class="mr-2"
                                    v-on="on"
                                    @click="openDialogModificarNroSeguimiento(item)"
                                    >mdi-truck-delivery</v-icon>
                                </template>
                                <span>Nro. Seguimiento</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on }" v-if="(item.carrier.api_data != null && item.carrier_api_data_obj && item.carrier_api_data_obj.multi_package != 0)">
                                    <v-icon
                                    default
                                    color="#8C8C8C"
                                    class="mr-2"
                                    v-on="on"
                                    @click="openDialogModificarNroBultos(item)"
                                    >mdi-counter</v-icon>
                                </template>
                                <span>Nro. Bultos</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon default color="#8C8C8C" class="mr-2" v-on="on" @click="openDialogRegenerarEtiqueta(item)" >mdi-barcode</v-icon>
                                </template>
                                <span>Regenerar Etiqueta</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="item.tracking_url">
                                <template v-slot:activator="{ on }">
                                    <v-icon                          
                                    style="font-size: 1.5rem !important;"
                                    small
                                    color="#8c8c8c"
                                    class="mr-2"
                                    v-on="on"
                                    @click="openShippingLink(item.tracking_url)"
                                    >mdi-truck-delivery</v-icon>
                                </template>
                                <span>Link Seguimiento</span>
                                </v-tooltip>
                            </td>
                            </template>
                            <template v-slot:item.estado="{ item }">
                                <td class="d-flex flex-column">
                                    <button
                                    :class="'btn btn-sm btn-primary'"
                                    v-text="item.current_state"
                                    ></button>
                                </td>
                            </template>
                            <template v-slot:item.fuente="{ item }">
                                <td class="text-center">
                                    {{ item.source.name ? item.source.name : 'Sin asignar' }}
                                </td>
                            </template>
                        </v-data-table>
                        <div class="d-flex justify-space-between mt-4">
                            <v-select style="max-width: 120px" @input="fetchDataPaginated" v-model="selectItemsPerPage" label="Registros por página" :items="[5,10,20,50,100]"></v-select>
                            <v-pagination @input="fetchDataPaginated" v-model="page" :length="totalPages"></v-pagination>
                        </div>
                        <br><br>
                        <v-btn @click="openModalCargarManifiestos" v-show="selected.length" small color="success" dark class="float-left"
                        ><v-icon left dark>mdi-format-list-checks</v-icon> Cargar manifiesto</v-btn>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-app>
    <modal-cargar-manifiestos @success="fetchDataPaginated"></modal-cargar-manifiestos>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import format from 'date-fns/format';
import ModalCargarManifiestos from '@/views/oms/Envios/ModalCargarManifiestos.vue';

export default {
    components:{
        ModalCargarManifiestos
    },
    data(){
        return {
            page: 1,
            dates: [],
            menu1: false,
            dateRangeText: "",
            search: '',
            table: [],
            selected: [],
            couriersList: [],
            dataTableLoading: false,
            totalPages: 1,
            itemsPerPage: 10,
            selectItemsPerPage: 10,
            carrierList: [],
            selectCarrier: 0,
            tableData: [],
        }
    },
    mounted(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Cargar manifiesto(s)", route: "/envios/cargar-manifiestos" },
        ]);
        this.fetchDataPaginated();
        this.fetchCouriers();
    },
    methods: {
        limpiarFiltros() {
            this.search = "";
            this.dateRangeText = "";
            this.selectCarrier = "Todos";
            this.fetchDataPaginated();
        },
        verRefSelect() {
            console.log("select change", this.$refs.select_carrier.selectedItems);
        },
        fetchCouriers() {
            var vm = this;
            this.axios({
                method: "GET",
                url: "shippings/couriers/data_select_input",
            })
            .then((response) => {
                vm.couriersList = response.data;
                vm.carrierList = response.data;
                vm.carrierList.push({ text: "Todos", value: 0 });
            })
            .catch((error) => {
                console.log(error);
            });
        },
        formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat)
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat)
            }
            return formattedDates
        },
        filtroRangoFechas(value) {
            if (!this.dateRangeText || !this.dates.length) {
                return true;
            } else {
                if ( this.$moment(value).isSameOrAfter(this.$moment(this.dates[0])) && this.$moment(value).isSameOrBefore(this.$moment(this.dates[1]))) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        formatearRango() {
            if (!this.dates.length) {
                this.dateRangeText = "";
            } else {
                this.dateRangeText = this.dates.join(" ~ ");
            }
        },
        limpiarRango() {
            this.search = "";
            this.dates = [];
        },
        fetchData(){
            var vm = this;
            this.axios({
                url: 'shippings/json',
                method: 'GET',
            }).then( response => {
                vm.table = response.data.data.filter(e => !e.manifest);
            }).catch( error => {
                console.log(error);
            });
        },
        fetchDataPaginated(){
            this.dataTableLoading = true;
            var vm = this;
            const sortBy = 'id';
            const sortDesc = true;
            const page = this.page;
            const itemsPerPage = this.selectItemsPerPage;
            const search = this.search;
            const filterManifestCondition = "no";
            const startDate = !(this.dateRangeText.split(' ~ ').length == 2) ? null : this.dateRangeText.split(' ~ ')[0];
            const endDate = !(this.dateRangeText.split(' ~ ').length == 2) ? null : this.dateRangeText.split(' ~ ')[1];
            const filterCarrier = this.selectCarrier ? this.selectCarrier : null;
            this.axios({
                    url: "shippings/paginated/json",
                    method: "GET",
                    params: {
                    page,
                    perPage: itemsPerPage,
                    sortBy: sortBy,
                    sortDesc: sortDesc ? 1 : 0,
                    search,
                    startDate,
                    endDate,
                    filterManifestCondition,
                    filterCarrier,
                },
            })
            .then((response) => {
                let arreglo = [];
                if(response.data.data instanceof Object){
                    Object.keys(response.data.data).forEach(key => {
                        arreglo.push(response.data.data[key]);
                    });
                    vm.tableData = arreglo;
                }else{
                    vm.tableData = response.data.data.map(e => e);
                }
                vm.totalPages = response.data.last_page;
                vm.dataTableLoading = false;
            })
            .catch((error) => {
                console.log(error);
                vm.dataTableLoading = false;
            });
        },
        openModalCargarManifiestos(){
            var courier = false;
            var sameCourier = true;
            this.selected.forEach((elem) => {
                if(courier == false){
                    courier = elem.carrier_name;
                }else{
                    if(courier != elem.carrier_name){
                        sameCourier = false;
                    }
                }

            });
            if(!sameCourier){
                alert("Todos los envios seleccionados deben ser del mismo courier");
                return;
            }
            this.$emit('openModalCargarManifiestos', this.selected, courier);
        }
    },
    computed: {
        headers() {
            return [
                { text: "# de orden", align: "left", sortable: false, value: "order_id" },
                { text: "Referencia", value: "order_reference" },
                { text: "Número de Envío", value: "shipping_number" },
                { text: "Fecha", value: "updated_at" },
                { text: "Carrier", value: "carrier_name" },
                { text: 'Estado', value: 'estado' },
                { text: 'Fuente', value: 'fuente' },
            ];
        },
    }
}
</script>